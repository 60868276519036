import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class FeaturesV1 extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'

    let customClass = this.props.customClass ? this.props.customClass :''

    return <div className={ customClass } >
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Roadmap</h6>
			          <h1 className="section-title">Why invest with OV?</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__custom-gutter--- justify-content-center go-top">
			      <div className="col-lg-3 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/OV1.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3><Link to="/product-details">Phase 1<br/>Blair Creek</Link></h3>
			            <p>Blair Creek Townhomes is an exclusive real estate project launching in Kitchener, Ontario, Canada. Invest into one of ten freehold townhomes being built, and have your NFTs backed by physical real estate. Maximize your investment for a chance to receive exclusive airdrops.</p>
			            {/* <Link className="ltn__service-btn" to="/service-details">Find A Home <i className="flaticon-right-arrow" /></Link> */}
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-3 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1 active">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/OV2.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3>Phase 2<br/>OV Passport</h3>
			            <p>The passport will provide you with lifetime access to investment opportunities that are traditionally unavailable to the public. Holders will have access to exclusive investment opportunities, whitelists on future NFTs, tiered benefits, and events.</p>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-3 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/OV3.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3>Phase 3<br/>Market Place</h3>
			            <p>Open Venture will provide crypto-adopters with a secure platform to trade real estate backed NFTs with cryptocurrencies, providing them with the liquidity to sell their investments without being tied to the completion date of projects.</p>
			          </div>
			        </div>
			      </div>
            <div className="col-lg-3 col-sm-6 col-12">
			        <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
			          <div className="ltn__feature-icon">
			            <img src={publicUrl+"assets/img/icons/icon-img/OV4.png"} alt="#" />
			          </div>
			          <div className="ltn__feature-info">
			            <h3>Phase 4<br/>Partnerships</h3>
			            <p>Open Venture will partner with real estate developers, media companies, and tech firms to provide investment opportunities, typically available to only high-profile investors, such as preconstruction real estate, film production, and software startups.</p>
			          </div>
			        </div>
			      </div>
			    </div>
          <div className="mt-5 pt-120">
            <div className="col-lg-12">
			        <div className=" ltn__section-title-2--- text-center">
			          <h1 className="section-title">Portfolio Companies</h1>
			        </div>
			      </div>
          </div>

          <div className="row ltn__custom-gutter--- justify-content-center go-top">
            <div className="col-lg-3 text-center">
              <a href="https://theannexhotels.com/" target="_blank"><img src="/assets/img/companies/Annex.png" style={{width:'200px'}} /></a>
            </div>
            <div className="col-lg-3 text-center">
              <a href="https://www.knowledgehook.com/" target="_blank"><img src="/assets/img/companies/Knowledge Hook.png" style={{width:'200px'}} /></a>
            </div>
            <div className="col-lg-3 text-center">
              <a href="https://www.redhillentertainment.ca/" target="_blank"><img src="/assets/img/companies/Redhill.png" style={{width:'200px'}} /></a>
            </div>
            <div className="col-lg-3 text-center">
              <a href="https://tredish.com/" target="_blank"><img src="/assets/img/companies/Tredish.png" style={{width:'200px'}} /></a>
            </div>
          </div>
        </div>

			</div>
        }
}

export default FeaturesV1