import React, { Component, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { auth, app, db } from '../../firebase';
import { collection, query, where, addDoc, getDocs, getDoc, setDoc, doc } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import {createUserWithEmailAndPassword} from 'firebase/auth'
import { useEthers } from '@usedapp/core';
import 'firebase/compat/auth';

//const kycRef = collection(db, "kyc");



async function getKyc(uid) {
  const docRef = doc(db, "kyc",  uid);
  return await getDoc(docRef);
  // const q = query(kycRef, where("uid", "==", uid));
  // return await getDocs(q);
}

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  //signInSuccessUrl: '/',
  callbacks: {
    signInSuccess: async (user) => {
      // let test = db.collection("kyc").doc(user.uid).get()
      // console.log(test);
      const doc = await getKyc(user.uid)
      console.log(doc)
      if (doc.empty) {
        window.location.href = process.env.PUBLIC_URL+'/register'
      } else {
        window.location.href = process.env.PUBLIC_URL+'/'
      }

      return false;
    }
  },

  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
};

const Register = () => {
  const navigate = useNavigate();
  const { account } = useEthers();
  let publicUrl = process.env.PUBLIC_URL+'/'

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },[]);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [unit, setUnit] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [error, setError] = useState("");

  const validatePassword = () => {
    let isValid = true
    if (password !== '' && confirmPassword !== ''){
      if (password !== confirmPassword) {
        isValid = false
        setError('Passwords do not match')
      }
    }
    return isValid
  }

  const register = e => {
    e.preventDefault()
    setError('')
    if(validatePassword()) {
      // Create a new user with email and password using firebase
        createUserWithEmailAndPassword(auth, email, password)
        .then(async (res) => {
          console.log(res);
          let result = await setDoc(doc(db, 'users', res.user.uid), {
            // account,
            phone,
            firstName,
            lastName,
            address,
            unit,
            city,
            state,
            country,
            zip
          });
          console.log(res.user)
          navigate('/kyc')
        }).catch(err => setError(err.message))
    }
    setEmail('')
    setPassword('')
    setConfirmPassword('')
  }

    return	<div className="mt-5" style={{height: '100%'}}>
			 <div className="ltn__login-area pb-65">
				<div className="container">
				{/* <div className="row">
					<div className="col-lg-12 mt-1">
					<div className="section-title-area text-center">
						<h1 className="section-title">Register Your Account</h1>
            { !account
            ? <Alert variant="danger">
              In order to proceed with registration, connect your Ethereum wallet by clicking the Connect button above.
            </Alert>
            : ''
            }
					</div>
					</div>
				</div> */}
        {error && <Alert variant='danger'>{error}</Alert>}
        {<Form onSubmit={register}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" value={email} required onChange={e => setEmail(e.target.value)} placeholder="Enter email" />
            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" value={firstName} required onChange={e => setFirstName(e.target.value)} placeholder="First Name" />

            {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="name">
          <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" value={lastName} required onChange={e => setLastName(e.target.value)} placeholder="Last Name" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" value={password} required onChange={e => setPassword(e.target.value)} placeholder="Password" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control type="password" value={confirmPassword} required onChange={e => setConfirmPassword(e.target.value)} placeholder="Confirm Password" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control type="text" value={phone} onChange={e => setPhone(e.target.value)} required pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder="416-555-5555" label="Phone Number" />
          </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Label>Address</Form.Label>
              <Form.Control type="text" value={address} onChange={e => setAddress(e.target.value)} required label="Address" placeholder="Address" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Label>Unit</Form.Label>
              <Form.Control type="text" value={unit} onChange={e => setUnit(e.target.value)} required label="Address" placeholder="Unit" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Label>City</Form.Label>
              <Form.Control type="text" value={city} onChange={e => setCity(e.target.value)} required label="City" placeholder="City" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Label>State/Province</Form.Label>
              <Form.Control type="text" value={state} onChange={e => setState(e.target.value)} required label="State/Province" placeholder="State" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Label>Country</Form.Label>
              <Form.Control type="text" value={country} onChange={e => setCountry(e.target.value)} required label="Country" placeholder="Country" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Label>Zip Code/Postal Code</Form.Label>
              <Form.Control type="text" value={zip} onChange={e => setZip(e.target.value)} required label="Address" placeholder="Zip Code/Postal Code" />
            </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" required label="I verify that this information is accurate to the best of my knowledge." />
          </Form.Group>
          <Button  variant="primary" type="submit">
            Submit
          </Button>
        </Form>

          }
        {/* <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} /> */}
				</div></div></div>
}

export default Register