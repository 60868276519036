import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ProductSlider from './shop-components/product-slider-v1';
import ProductDetails from './shop-components/shop-details';
import ProductDetailsBlackCat from './shop-components/shop-details-blackcat';
import ProductDetailsTredish from './shop-components/shop-details-tredish';
import CallToActionV1 from './section-components/call-to-action-v1';
import FooterNew from './global-components/footer-new';
import FAQ from './section-components/faq';

const Product_Details = () => {
    const { name } = useParams();
    console.log(name);
    let productDetails;

    if (name === 'blair-creek') {
      productDetails = <ProductDetails />
    } else if (name === 'black-cat') {
      productDetails = <ProductDetailsBlackCat />
    } else if (name === 'tredish') {
      productDetails = <ProductDetailsTredish />
    }

    return <div>
        <Navbar />
        {/* <PageHeader headertitle="Product Details" customclass="mb-0" /> */}
        {/* <ProductSlider /> */}

        {productDetails}
        <FAQ/>
        {/* <CallToActionV1 /> */}
        <FooterNew />
    </div>
}

export default Product_Details

