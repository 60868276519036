import React from 'react';
import { Link } from 'react-router-dom';

const Footer_New = () => {
  const $ = window.$;

        let publicUrl = process.env.PUBLIC_URL+'/'
        const minscript = document.createElement("script");
        minscript.async = true;
        minscript.src = publicUrl + "assets/js/main.js";

        document.body.appendChild(minscript);

         $('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

				$(window).scrollTop(0);

			setTimeout(function(){
			    	$(".quarter-overlay").fadeOut(300);
				}, 800);

        });


		$(document).on('click','.theme-btn-1 ', function(){
            $( 'div' ).removeClass( 'modal-backdrop' );
            $( 'div' ).removeClass( 'show' );
            $( 'div' ).removeClass( 'fade' );
			$('body').attr("style", "");
        });
  return(
    <div style={{background: '#122549', color: '#FFF !important'}} className="pt-100 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-6" style={{fontSize: '72px'}}>
            <h1 style={{color: '#FFF'}}>Invest With Open Venture</h1>

            <div className="ltn__copyright-menu" style={{color: '#FFF'}}>
            <ul className="go-top">
              {/* <li><Link to="/contact">Contact</Link></li> */}
              <li><a href="/assets/documents/tnc.pdf" target="_blank">Terms &amp; Conditions</a></li>
              {/* <li><a href="/about">About Us</a></li> */}
              {/* <li><Link to="/about">Claim</Link></li> */}
              {/* <li><Link to="/about">Privacy &amp; Policy</Link></li> */}
            </ul>
            </div>

            <span style={{fontSize:'13px', color: '#FFF'}}>All Rights Reserved @ Open Venture Inc. <span className="current-year" /></span>

          </div>
          <div className="col-lg-6">
            <p style={{color: '#FFF', width: '370px'}}>Open Venture is the world’s first platform to tokenize early-stage investment opportunities to redistribute value for everyone.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer_New;