import { useCall } from '@usedapp/core';
import ContractData from '../abi';
import { Interface } from '@ethersproject/abi';
import { Contract } from '@ethersproject/contracts';
import { formatUnits } from '@ethersproject/units';

const nftInterface = new Interface(ContractData['blaircreek'].abi);
let address = ContractData['blaircreek'].address;
let nftContract = new Contract(address, nftInterface);

// export function useGetPrice() {
//   const price = useContractCall({
//     abi: nftInterface,
//     address,
//     method: 'getPriceInCad',
//     args: []
//   }) ?? [];

//   console.log(price)

//   if (!price.length) {
//     return { value: [0] }
//   } else {
//     return { value: price }
//   }

// }

export const useGetPrice = () => {

  // try {
  //   let x = await nftContract.eth_usd_price_feed()
  //   console.log(x)
  // } catch(e){
  //   console.log(e);
  // }
  const price = useCall({
    contract: nftContract,
    method: 'getPriceInCad',
    args: [],
  }) ?? [];

  return price;
}


// export function useTotalSupply() {
//   const supply = useContractCall({
//     abi: nftInterface,
//     address,
//     method: 'totalSupply',
//     args: []
//   }) ?? []

//   console.log(supply);
// }

export function useTotalSupply() {
  const { value, error } = useCall({
    contract: nftContract,
    method: 'totalSupply',
    args: []
  }) ?? {}

  console.log("value", value)
  console.log("error", error)

  if(error) {
    console.error(error.message)
    return undefined
  }

  if (value && value[0]) {
    return parseFloat(formatUnits(value[0], 0))
  } else {
    return 0
  }
}


// OLD
// export function useGetTotalSupply(contract) {
//   let totalSupply = useCall({
//     contract,
//     method: 'totalSupply',
//     args: []
//   }) ?? [];
//   console.log("total supply", totalSupply)
//   if (totalSupply.value && totalSupply.value[0]) {
//     console.log(parseFloat(formatUnits(totalSupply.value[0], 0)));
//     totalSupply = parseFloat(formatUnits(totalSupply.value[0], 0))
//   } else {
//     totalSupply = 0;
//   }

//   return totalSupply;
// }