import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTotalSupply } from '../../hooks';
import ContractData from '../../abi';
import { Interface } from '@ethersproject/abi';
import { Contract } from '@ethersproject/contracts';

const AboutV1 = () => {
  const nftInterface = new Interface(ContractData['blaircreek'].abi);
  let address = ContractData['blaircreek'].address;
  let nftContract = new Contract(address, nftInterface);

  const totalSupply = useTotalSupply();
  const maxTokens = ContractData['blaircreek'].maxTokens;
  const progress = ((totalSupply/maxTokens) * 100).toFixed(2);
  let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pt-90 pb-90 ">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-img-wrap about-img-left">
              <img src={publicUrl+"assets/img/blaircreek.gif" } alt="video popup bg image" />
			          {/* <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
			            <div className="ltn__video-img ltn__animation-pulse1">
			              <img src={publicUrl+"assets/img/blaircreek.gif" } alt="video popup bg image" />
			              <a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
			                <i className="fa fa-play" />
			              </a>
			            </div>
			          </div> */}
			        </div>
			      </div>
			      <div className="col-lg-6 align-self-center">
			        <div className="about-us-info-wrap">
			          <div className="section-title-area ltn__section-title-2--- mb-20">
			            {/* <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6> */}
			            <h1 className="section-title">Blair Creek Townhomes<span>.</span></h1>
                  {typeof totalSupply === 'number'
                  ? <ProgressBar animated variant="primary" now={progress} />
                  : ''
                  }
                  <small className="mb-5" style={{float: 'right'}}>{totalSupply}/{maxTokens} ({progress}%)</small>
			            <p className="mt-5">Securely invest in NFTs backed by a brand new townhome project built in the upscale region of Blair Creek in Kitchener, Ontario, Canada. </p>
                  <p>Each NFT represents fractional ownership of a share in a freehold townhome, which provides secure real-world value and profits shared amongst the NFT holders upon the completion and sale of the development project.</p>
			          </div>
			          <ul className="ltn__list-item-half clearfix">
			            <li>
			              <i className="flaticon-home-2" />
			              Available: 8490
			            </li>
			            <li>
			              <i className="flaticon-financial" />
			              Price: $1000 CAD / Unit <br/>
                    (In Ethereum)
			            </li>
			            {/* <li>
			              <i className="flaticon-heart" />
			              Exceptional Lifestyle
			            </li>
			            <li>
			              <i className="flaticon-secure" />
			              Complete 24/7 Security
			            </li> */}
			          </ul>
			          {/* <div className="ltn__callout bg-overlay-theme-05  mt-30">
			            <p>"Enimad minim veniam quis nostrud exercitation <br />
			              llamco laboris. Lorem ipsum dolor sit amet" </p>
			          </div> */}
			          <div className="btn-wrapper animated go-top">
                  <div className="col-lg-6 col-xs-9 mobile-center">
			              <Link to="/product-details" style={{width: '80%'}} className="theme-btn-1 btn btn-effect-1">Learn More</Link>
                  </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

}

export default AboutV1