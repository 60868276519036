import React, { Component, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Social from '../section-components/social';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, app, db } from '../../firebase';
import { signOut } from 'firebase/auth';
import { Web3ModalButton } from '../Web3ModalButton'
import { doc, getDoc } from "firebase/firestore";
import './navbar.css';
import Logo from './logo.svg';

function Navbar() {
  const [user, loading, error] = useAuthState(auth);
  const [uid, setUid] = useState("");
  const [dbUser, setDbUser] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
		async function setData() {
			if (user && !loading) {
				const dbuser = await getDoc(doc(db, "users",  user.uid))
				setDbUser(dbuser.data());
			}
		}
		setData();
  }, [user, loading])

  const logout = () => {
    signOut(auth);
    window.location="/";
  }

  let publicUrl = process.env.PUBLIC_URL+'/'

  return (
		<div>
    <header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---" style={{zIndex: '9999'}}>
			<div className="ltn__header-middle-area ltn__header-sticky" style={{background: '#122549'}}>
				<div className="container">
				<div className="row">
					<div className="col-2">
            <div className="">
              <div className="site-logo go-top">
              <Link to="/"><img src={Logo} alt="Logo" /></Link>
              </div>
            </div>
					</div>
					<div className="col header-menu-column">
					<div className="header-menu d-none d-xl-block">
						<nav>
						<div className="ltn__main-menu go-top">
							<ul>
							{/* <li className=""><Link to="/">About</Link></li> */}
              <li><span className="navitem">Projects <span style={{position: 'absolute', color: '#8892a5', right: '20px', bottom: '25px', color: '#FFF'}}>⌄</span></span>
                <ul id="project-submenu" className="" style={{color: '#000 !important'}}>
                  <h3 style={{padding:'20px', color: '#6B717D'}}>Our Projects</h3>
                  <li style={{color: '#000 !important'}}>
                    <Link to="/project/blair-creek">
                      <div className="row">
                        <div className="col-2">
                          <img src="/assets/img/blaircreekicon.png" />
                        </div>
                        <div className="col-9">
                        Blair Creek Townhomes
                        <p style={{color: '#6B717D', fontSize: '14px'}}>Blair Creek Townhomes is a premium real estate project in the upscale region of Blair Creek in Kitchener, Ontario, Canada.</p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li style={{color: '#000 !important'}}>
                    <Link to="/project/black-cat">
                      <div className="row">
                        <div className="col-2">
                          <img src="/assets/img/blackcaticon.png" />
                        </div>
                        <div className="col-9">
                        Black Cat
                        <p style={{color: '#6B717D', fontSize: '14px'}}>Black Cat is a horror movie based on The Black Cat by American writer Edgar Allan Poe and produced by Red Hill Entertainment.</p>
                        </div>
                      </div>
                    </Link>

                  </li>
                  <li style={{color: '#000 !important'}}>
                    <Link to="/project/tredish">
                      <div className="row">
                        <div className="col-2">
                          <img src="/assets/img/tredishicon.png" />
                        </div>
                        <div className="col-9">
                        Tre'dish
                        <p style={{color: '#6B717D', fontSize: '14px'}}>
                        Tredish is a brand new platform that empowers home chefs with the tools to grow a profitable business.
                        </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  {/* <li>OV Passport - Coming Soon</li> */}
                  {/* <li>West Harbour - Sold Out</li>
                  <li>Trafalgar Meadows - Sold Out</li> */}
                </ul>
              </li>
              {/* <li><Link to="/about">About</Link></li> */}
							{/* <li><Link to="/contact">Contact</Link></li> */}
              <li><a href="/assets/documents/litepaper.pdf" target="_blank">Litepaper</a></li>
							</ul>
						</div>
						</nav>
					</div>
					</div>
					<div className="col-3 ltn__main-menu go-top" style={{zIndex: '9999'}}>
            <ul className="d-none d-xl-inline" style={{right: '0px', zIndex: '9999', color: '#FFF'}}>

              <li><Web3ModalButton /></li>
              <li><button onClick={() => navigate('/register')} style={{color: '#FFF'}}><i style={{fontSize: '20px', color: '#FFF', marginRight: '10px'}} className="fa-solid fa-user"></i>Sign Up</button></li>
            </ul>

            <div className="mobile-menu-toggle d-xl-none">
              <a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle link-toggle">
                {/* <i class="fa-solid fa-bars"></i> */}
              <svg viewBox="0 0 800 600">
                <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
                <path d="M300,320 L540,320" id="middle" />
                <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
              </svg>
              </a>
            </div>
					</div>
				</div>
				</div>
			</div>
			</header>
			<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu" style={{zIndex: '9999'}}>
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
					<div className="site-logo">
						<Link to="/"><img src={publicUrl+"assets/img/newlogo.png"} alt="Logo" /></Link>
					</div>
					<button className="ltn__utilize-close">×</button>
					</div>
					<div className="ltn__utilize-menu-search-form">
					{/* <form action={"#"}>
						<input type="text" placeholder="Search..." />
						<button><i className="fas fa-search" /></button>
					</form> */}
					</div>
					<div className="ltn__utilize-menu">
					<ul>
						<li>
              <Link to="/">Home</Link>
						</li>
            <li><a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle link-toggle">Projects</a>
              <ul className="sub-menu" style={{color: '#FFF'}}>
                <li><Link to="/project/blair-creek">Blair Creek</Link></li>
                <li><Link to="/project/black-cat">Black Cat</Link></li>
                <li><Link to="/project/tredish">Tre'dish</Link></li>
                {/* <li>OV Passport - Coming Soon</li> */}
                {/* <li>West Harbour - Sold Out</li>
                <li>Trafalgar Meadows - Sold Out</li> */}
              </ul>
            </li>
            <li><a href="/assets/documents/litepaper.pdf" target="_blank">Litepaper</a></li>
					</ul>
					</div>
					{/* <div className="ltn__social-media-2">
					<ul>
						<li><a href="https://twitter.com/openventureinc" title="Twitter"><i className="fab fa-twitter" /></a></li>
						<li><a href="https://www.instagram.com/openventureinc" title="Instagram"><i className="fab fa-instagram" /></a></li>
					</ul>
					</div> */}
				</div>
        <div className="row" style={{position: 'absolute', bottom: '0px', left: '0px', borderTop: '1px solid #5E6B83', height:'70px', width:'100%'}}>
          <div className="col-6 d-flex align-items-center justify-content-center">
            <Web3ModalButton />
          </div>
          <div className="col-6 d-flex align-items-center justify-content-center">
            <button onClick={() => navigate('/register')} style={{background: '#227cff', color: '#FFF', padding:'20px', width:'100%'}}><i style={{fontSize: '20px', color: '#FFF', marginRight: '10px'}} className="fa-solid fa-user"></i>Sign Up</button>
          </div>
        </div>
			</div>
		</div>
  )
}


export default Navbar