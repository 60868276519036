import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TestimonialV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__testimonial-area ltn__testimonial-4 pt-115 pb-100 plr--9 go-top">
			  <div className="container-fluid">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Team</h6>
			          <h1 className="section-title">Over 40 Years of Combined Experience</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="ltn__testimonial-slider-4 ltn__testimonial-slider-4-active slick-arrow-1">
			          <div className="ltn__testimonial-item-5">
			            {/* <div className="ltn__quote-icon">
			              <i className="far fa-comments" />
			            </div> */}
			            <div className="ltn__testimonial-image"  style={{width:'500px !important'}}>
			              <img style={{width:'500px !important'}} src={publicUrl+"assets/img/testimonial/Surab.jpg"} alt="quote" />
			            </div>
			            <div className="ltn__testimonial-info">
                    <h4>Surab Khogiani</h4>
			              <h6>CEO</h6>
			              <p>Surab is a serial entrepreneur with over 15 + years of experience building multi-million dollar businesses in real estate development, tech startups, and franchise businesses.</p>

			            </div>
			          </div>
			          <div className="ltn__testimonial-item-5">
			            <div className="ltn__testimonial-image"><img src={publicUrl+"assets/img/testimonial/Charlie.jpg"} alt="quote" /></div>
			            <div className="ltn__testimonial-info">
                    <h4>Charlie Liu</h4>
			              <h6>CFO</h6>
			              <p>Charlie has over 10+ years of building professional services and Solutions Engineering departments in the Fintech space. He is a long-term advocate for real estate and tech angel investing, and he has been tinkering with cryptocurrency since 2016.</p>

			            </div>
			          </div>
			          <div className="ltn__testimonial-item-5">
			            <div className="ltn__testimonial-image"><img src={publicUrl+"assets/img/testimonial/Christo.jpg"} alt="quote" /></div>
			            <div className="ltn__testimonial-info">
			              <p></p>
			              <h4>Christo Mitov</h4>
			              <h6>CTO</h6>
                    <p>Christo has 2 years experiece in Solidity development, 6 years in Fintech Software development, and over 10 years in total development experience.</p>
			            </div>
			          </div>
			          <div className="ltn__testimonial-item-5">
			            <div className="ltn__testimonial-image"><img src={publicUrl+"assets/img/testimonial/Peter.jpg"} alt="quote" /></div>
			            <div className="ltn__testimonial-info">
			              <p></p>
			              <h4>Peter Kim</h4>
			              <h6>CLO</h6>
                    <p>Peter Kim, J.D., focuses his law practice in corporate and commercial transactions and commercial litigation. He has been involved in various start-ups and e-commerce matters.</p>
			            </div>
			          </div>
			        </div>
			        <ul className="ltn__testimonial-quote-menu d-none d-lg-block">
			          <li><img src={publicUrl+"assets/img/testimonial/Surab.jpg"} alt="Quote image" /></li>
			          <li><img src={publicUrl+"assets/img/testimonial/Charlie.jpg"} alt="Quote image" /></li>
			          <li><img src={publicUrl+"assets/img/testimonial/Christo.jpg"} alt="Quote image" /></li>
			          <li><img src={publicUrl+"assets/img/testimonial/Peter.jpg"} alt="Quote image" /></li>
			        </ul>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default TestimonialV3