import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { auth, app, db } from '../../firebase';
import { collection, query, where, addDoc, getDocs, getDoc, setDoc, doc } from "firebase/firestore";
import firebase from 'firebase/compat/app';
import {createUserWithEmailAndPassword} from 'firebase/auth'
import 'firebase/compat/auth';

//const kycRef = collection(db, "kyc");



async function redirect(uid) {
  const docRef = doc(db, "users",  uid);
  const document = await getDoc(docRef);
  console.log("DOCUMENT", document.data())
  if (!document.data().kyc) {
    window.location.href = process.env.PUBLIC_URL+'/kyc'
  } else {
    window.location.href = process.env.PUBLIC_URL+'/'
  }
}

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  //signInSuccessUrl: '/',
  callbacks: {
    signInSuccess: async (user) => {
      // let test = db.collection("kyc").doc(user.uid).get()
      // console.log(test);
      redirect(user.uid)
      return false;
    }
  },

  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
};

const Login = () => {
  let publicUrl = process.env.PUBLIC_URL+'/'

    return	<div className="mt-5" style={{height: '80vh'}}>
			 <div className="ltn__login-area pb-65">
				<div className="container">
				<div className="row">
					<div className="col-lg-12 mt-1">
					<div className="section-title-area text-center">
						<h1 className="section-title">Login</h1>
            {/* <br />To  Your Account</h1> */}
						{/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. <br />
						Sit aliquid,  Non distinctio vel iste.</p> */}
					</div>
					</div>
				</div>


        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
				</div></div></div>
}

export default Login