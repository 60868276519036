import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Register from './section-components/register';
import CallToActionV1 from './section-components/call-to-action-v1';
import FooterNew from './global-components/footer-new';

const RegisterPage = () => {
    return <div>
        <Navbar />
        {/* <PageHeader headertitle="Account" subheader="Login" /> */}
        <Register />
        {/* <CallToActionV1 /> */}
        <FooterNew />
    </div>
}

export default RegisterPage

