import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import KYC from './section-components/kyc';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';

import './Register.css';

const KYCPage = () => {
    return <div>
        <Navbar />
        {/* <PageHeader headertitle="Account" subheader="KYC" /> */}
        <KYC />
        {/* <CallToActionV1 /> */}
        <Footer />
    </div>
}

export default KYCPage

