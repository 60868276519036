import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Banner extends Component {

    render() {
			console.log(process.env)
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return    <div className="ltn__slider-area ltn__slider-3  section-bg-1 section-bg go-top pb-100" style={{background: '#122549'}}>
				  <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
				    {/* ltn__slide-item */}
				    <div className="ltn_slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3">
				      <div className="ltn__slide-item-inner">
				        <div className="container">
				          <div className="row">
				            <div className="col-lg-6 align-self-center">
				              <div className="slide-item-info">
				                <div className="slide-item-info-inner ">
				                  {/* <div className="slide-video mb-50 d-none">
				                    <a className="ltn__video-icon-2 ltn__video-icon-2-border" href="https://www.youtube.com/embed/tlThdr3O5Qo" data-rel="lightcase:myCollection">
				                      <i className="fa fa-play" />
				                    </a>
				                  </div> */}
				                  {/* <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> Real Estate Agency</h6> */}
				                  <h1 className="slide-title animated" style={{fontSize: '96px', color: '#FFF', fontWeight: '400'}}>Venture <br /> Capital 3.0</h1>
				                  {/* <div className="slide-brief animated">
				                    <p>Open Venture is the world’s first platform to democratize early-stage investment opportunities for everyone.</p>
                            <p>Backed by real estate, media production, and tech startups, each NFT provides you with fractional ownership in projects secured by real-world value.</p>
				                  </div> */}
				                  {/* <div className="btn-wrapper animated row">
                              <div className="col-lg-6 col-xs-9 mobile-center">
				                    	<Link to="/product-details" style={{width: '80%'}} className="theme-btn-1 btn btn-effect-1 go-top">Mint</Link>
                              </div>
				                  </div> */}
				                </div>
				              </div>
				              {/* <div className="slide-item-img hidden-xs hidden-sm" >
				                <img style={{ width: '700px' }} src={publicUrl+"assets/img/frontpage.png"} alt="#" />
				              </div> */}
				            </div>
                    <div className="col-lg-6 align-self-center" style={{zIndex: '9000', fontSize: '20px', color: '#FFF', width:'455px'}}>
                      Open Venture is the world’s first platform to democratize early-stage investment opportunities for everyone.
                    </div>
				          </div>
				        </div>
				      </div>
				    </div>
				    {/* ltn__slide-item */}
				    {/* <div className="ltn__slide-item ltn__slide-item-2  ltn__slide-item-3-normal ltn__slide-item-3">
				      <div className="ltn__slide-item-inner  text-right text-end">
				        <div className="container">
				          <div className="row">
				            <div className="col-lg-12 align-self-center">
				              <div className="slide-item-info">
				                <div className="slide-item-info-inner ltn__slide-animation">
				                  <h6 className="slide-sub-title white-color--- animated"><span><i className="fas fa-home" /></span> Real Estate Agency</h6>
				                  <h1 className="slide-title animated ">The Right Place <br />of House Finding</h1>
				                  <div className="slide-brief animated">
				                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
				                  </div>
				                  <div className="btn-wrapper animated">
				                    <Link to="/service" className="theme-btn-1 btn btn-effect-1">OUR SERVICES</Link>
				                    <Link to="/about" className="btn btn-transparent btn-effect-3">LEARN MORE</Link>
				                  </div>
				                </div>
				              </div>
				              <div className="slide-item-img slide-img-left">
				                <img src={publicUrl+"assets/img/slider/21.png"} alt="#" />
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				    </div> */}
				    {/*  */}
				  </div>
			</div>
        }
}

export default Banner