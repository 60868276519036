import React from 'react';
import { useNavigate } from 'react-router-dom'
const Partnerships = () => {
  const navigate = useNavigate();
  return(
    <div style={{background: '#eff1f2', position: 'relative', zIndex:'999', paddingTop:'80px', paddingBottom:'150px'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-4" style={{fontSize: '72px', lineHeight: '120%'}}>
          <h1>
            Why invest with OV?
          </h1>
        </div>
        <div className="col-lg-4">
          <p>
          Purchase fractional shares of unique investment opportunities early enough for high growth potential. No investment is too expensive, and timing is everything.
          </p>
        </div>
        <div className="col-lg-4">
          <img src="/assets/img/whyinvest.png" style={{marginTop:'70px', zIndex:'9999'}}/>
        </div>
      </div>
      <div className="row pt-80">
        <div className="col-lg-6" style={{background: '#122549'}}>
          <img src="/assets/img/partnerships.png" />
        </div>
        <div className="col-lg-6" style={{background: '#FFF', padding:'40px'}}>
          <form>
            <div className="ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- mb-20">
              <div className="nav">
                <a className="show active" data-bs-toggle="tab" href="#phase1">Phase 1</a>
                <a data-bs-toggle="tab" href="#phase2">Phase 2</a>
                <a data-bs-toggle="tab" href="#phase3">Phase 3</a>
              </div>
            </div>
            <div className="tab-content">
              <div className="tab-pane fade active show" id="phase1">
                <h2 className="mb-20" style={{fontSize: '40px'}}>Partnerships</h2>
                <p>
                  Open Venture will partner with real estate developers, media companies, and tech firms to provide investment opportunities typically available to only high-profile investors. These include pre-draft plan real estate projects, film production, and software startups.
                </p>
              </div>
              <div className="tab-pane fade" id="phase2">
                <h2 className="mb-20" style={{fontSize: '40px'}}>OV Passport</h2>
                <p>
                  The passport will give you lifetime access to investment opportunities traditionally unavailable to the public. Holders will access exclusive investment opportunities, whitelists on future projects, tiered benefits, and events.
                </p>
              </div>
              <div className="tab-pane fade" id="phase3">
                <h2 className="mb-20" style={{fontSize: '40px'}}>Marketplace</h2>
                <p>
                  Open Venture will provide investors with a secure platform to trade units issued by OV with fiat currency and cryptocurrencies, providing them with the liquidity to sell their investments without being tied to the completion date of projects.
                </p>
              </div>
            </div>
            <button onClick={() => navigate('/register')}style={{background: '#227CFF', padding:'20px', color: '#FFF', marginTop:'20px'}}>Register→</button>
          </form>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Partnerships;