import blaircreek from './BlairCreek.json';

const ContractData = {
  blaircreek: {
    title: 'Blair Creek Freehold Townhomes',
    // address: '0xf004d2f46e177eC8Ffc7c7c7f2623E212b450920',
    address: '0xA815C18B860716f56587552A80aC284045b79e29', // mainnet fork
    testnetAddress: '0x85631f65e1725cdb6DdFE4a85D8c5c6d02674852',
    abi: blaircreek.abi,
    soldOut: true,
    max: 20,
    price: 250,
    discountPrice: 200,
    maxTokens: 8490,
    cid: 'QmPycSCFZ4Qtrz652pQHxXnTd6zCgyULJdVTJTYZcMWZDc'
  }
}

export default ContractData;