import React from 'react';
import { useNavigate } from "react-router-dom";

const ProjectSlider = () => {
  const navigate = useNavigate();
  return (
    <div style={{zIndex: '999', position: 'relative', background: '#FFF'}}>
    <div id="project-container" className="container pb-100">
      <div id="row" className="row pt-100 row-eq-height">
        <div className="col-lg-9 align-self-center">
          <div className="row project">

            <div className="order-xs-last order-sm-last order-lg-first col-lg-4 align-self-center">
            <h1 style={{fontSize: '36px'}}>Blair Creek Townhomes</h1>
              {/* <div className="d-none d-lg-block" style={{fontSize: '36px'}}>
                <i className="fa-solid fa-chevron-left" style={{cursor: 'pointer'}}></i>
                <i className="fa-solid fa-chevron-right" style={{cursor: 'pointer', marginLeft:'50px'}}></i>
              </div> */}
            </div>
            <div className="col-lg-8 align-self-center">
              <div className="row" style={{fontSize: '16px', fontWeight: '400', lineHeight: '150%', zIndex: '999'}}>
                <p>
                Blair Creek Townhomes is a premium real estate project in the upscale region of Blair Creek in Kitchener, Ontario, Canada.
                </p>
              </div>
              <div className="row row-eq-height pt-10">
                <div className="col-6 col-sm-6 col-lg-4" style={{paddingLeft: '0px !important'}}>
                  <h2>Available</h2>
                  <span style={{fontSize: '20px'}}>22/849</span>
                </div>
                <div className="col-6 col-sm-6 col-lg-4">
                  <h2>Price</h2>
                  <span style={{fontSize: '20px'}}>$1000 CAD/Unit</span>
                </div>

                <div onClick={() => navigate('/product-details')} className="col-lg-4 d-none d-sm-block" style={{color: '#227CFF', cursor: 'pointer'}}>
                  More Info→
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-20 d-block d-sm-none">
              {/* <div className="" style={{fontSize: '36px'}}>
                <i className="fa-solid fa-chevron-left" style={{cursor: 'pointer'}}></i>
                <i className="fa-solid fa-chevron-right" style={{cursor: 'pointer', marginLeft:'50px'}}></i>
              </div> */}
        </div>
        <div id="register-button" onClick={() => navigate('/register')} className="col-lg-3" style={{cursor: 'pointer', zIndex: '900', background: '#227CFF', color: '#FFF', display:'flex', width:'100% !important', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
          Register→
        </div>
      </div>
    </div>
    </div>
  );
}

export default ProjectSlider;