import React from 'react';
import './team.css';

const Team = () => {
  return (
    <div id="team" className="pt-30 pb-100" style={{background: '#eff1f2'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 order-xs-2 order-sm-2 order-2 order-lg-first">
            <div className="ray">
              <img src="/assets/img/team/ray.png" className="image"/>
              <div className="overlay">
                <p className="text">
                  Ray is a serial entrepreneur with over 15 + years of experience building multi-million dollar businesses in real estate development, tech startups, and franchise businesses.
                </p>
              </div>
            </div>

            <p>
              Ray Khogiani M.Sc
            </p>
            <span>Chief Executive Officer</span>
          </div>
          <div id="our-team" className="col-lg-6 order-xs-1 order-sm-1 order-lg-2" style={{padding: '0 140px', position:'relative'}}>
            <h1 style={{fontSize: '72px', lineHeight:'100%'}}>Our Team</h1>
            <h3 className="mt-20" style={{fontSize: '28px'}}>Over 40 Years of Combined Experience</h3>
            <img className='d-none d-sm-block' src="/assets/img/teamicon.png" style={{position:'absolute', bottom:'0px', right: '0px'}}/>
          </div>
        </div>
        <div className="row pt-60">
          <div className="col-lg-4">
            <div className="charlie">
              <img src="/assets/img/team/charlie.png" className="image" />
              <div className="overlay">
              <p className="text">
              Charlie has 10+ years of experience in building professional services and solution engineering departments in the Fintech space.
                </p>
              </div>
            </div>
            <p>
              Charlie Liu M.M.A
            </p>
            <span>Chief Finance Officer</span>
          </div>
          <div className="col-lg-4">
            <div className="peter">
              <img src="/assets/img/team/peter.png" className="image" />
              <div className="overlay">
                <p className="text">
                  Peter focuses his law practice around corporate transactions and commercial litigation, and he has helped build multiple fintech startups.
                </p>
              </div>
            </div>
            <p>
              Peter Kim J.D.
            </p>
            <span>Chief Legal Officer</span>
          </div>
          <div className="col-lg-4">
            <div className="christo">
              <img src="/assets/img/team/christo.png" className="image" />
              <div className="overlay">
                <p className="text">
                  Christo has 2 years experiece in Solidity development, 6 years in Fintech Software development, and over 10 years in total development experience.
                </p>

              </div>
            </div>
            <p>
              Christo Mitov
            </p>
            <span>Chief Technology Officer</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team;