import React, { useEffect, useState } from 'react'
import { useEthers, shortenAddress, useLookupAddress } from '@usedapp/core'
import Button from 'react-bootstrap/Button';
import styled from 'styled-components'
import Web3Modal from 'web3modal';
import { AccountModal } from './AccountModal';
// import WalletConnectProvider from '@deficonnect/web3-provider';
import WalletConnectProvider from "@walletconnect/web3-provider"
// import cdcLogo from '../images/defiwallet.svg';

export const Web3ModalButton = () => {
  const { account, activate, deactivate } = useEthers()
  const ens = useLookupAddress()
  const [showModal, setShowModal] = useState(false)
  const [activateError, setActivateError] = useState('')
  const { error } = useEthers()
  useEffect(() => {
    if (error) {
      setActivateError(error.message)
    }
  }, [error])

  const activateProvider = async () => {
    const providerOptions = {
      // injected: {
      //   display: {
      //     name: 'Metamask',
      //     description: 'Connect with the provider in your Browser',
      //   },
      //   package: null,
      // },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: '066727117cdc48ca9645d26d0c555e1d'
        }
        // options: {
        //   rpc: {
        //     25: "https://gateway.nebkas.ro",
        //     338: "https://evm-t3.cronos.org"
        //   },
        //   chainId: 25,
        //   network: 'cronos'
        // },
      },
    }

    console.log(providerOptions)

    const web3Modal = new Web3Modal({
      cacheProvider: true, // very important
      network: "mainnet",
      providerOptions
    })

    const provider = await web3Modal.connect()

    await provider.enable();
    await activate(provider)


    if (window.ethereum) {
      await window.ethereum.request({ method: 'wallet_switchEthereumChain', params:[{chainId: '0x1'}]});
    }

  }

  return (
    <Account>
      {/* <ErrorWrapper>{activateError}</ErrorWrapper> */}
      {showModal && <AccountModal setShowModal={setShowModal} />}
      {account ? (
        <>
          {/* <AccountLabel onClick={() => setShowModal(!showModal)}>{ens ?? shortenAddress(account)}</AccountLabel> */}
          <button id="connect-button" style={{color: '#FFF'}} onClick={() => deactivate()}><i style={{fontSize: '20px', color: '#FFF', marginRight: '10px'}} className="fa-solid fa-wallet"></i>Disconnect</button>
        </>
      ) : (
        <button id="connect-button" style={{color: '#FFF'}} onClick={activateProvider}><i style={{fontSize: '20px', color: '#FFF', marginRight: '10px'}} className="fa-solid fa-wallet"></i>Connect</button>
      )}
    </Account>
  )
}

const ErrorWrapper = styled.div`
  color: #ff3960;
  margin-right: 40px;
  margin-left: 40px;
  overflow: auto;
`

const Account = styled.div`
  margin-left:0px;
`

// const LoginButton = styled(Button)`
//   background-color: none;
// `

const AccountLabel = styled(Button)`
  height: 32px;
  margin-right: -40px;
  padding-right: 40px;
  padding-left: 8px;
  background-color: blue;
  font-size: 12px;
`