import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, app, db } from '../../firebase';
import { setDoc, doc } from "firebase/firestore";
import Persona from 'persona';
import { useEthers } from '@usedapp/core';

function KYC() {
  const navigate = useNavigate();
  const { account } = useEthers();
  const [user, loading, error] = useAuthState(auth);
  const [uid, setUid] = useState("");

  useEffect(() => {
    if (user && !loading) {
      console.log('setting uid to', user.uid);
      setUid(user.uid)
      window.uid = user.uid;
    }
  }, [user, loading])

  let publicUrl = process.env.PUBLIC_URL+'/'
  return (
    <div className="ltn__login-area pb-110 mt-5" style={{width:'100%', height: '100vh', minHeight:'100vh'}}>
				<div className="container">
				<div className="row">
					<div className="col-lg-12">
					<div className="section-title-area text-center">
          <Persona.Inquiry style={{width:'100%', height: '100vh', minHeight:'100vh'}}
            templateId='itmpl_vcFqAuNW1KJN6JzvxBUdGuz3'
            environment='production'
            onLoad={() => { console.log('Loaded inline'); }}
            onComplete={async ({ inquiryId, status, fields }) => {
              console.log(window.uid);
              let result = await setDoc(doc(db, 'users', window.uid), { kyc: { status, inquiryId, fields } }, { merge:true });
              console.log(status);
              if (status === 'completed') {
                navigate('/')
              }
            }}
          />
						{/* <h1 className="section-title">Register <br />Your Account</h1>
						<p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. <br />
						Sit aliquid,  Non distinctio vel iste.</p> */}
					</div>
					</div>
				</div>
				</div>
			</div>
  )
}

export default KYC