import React from 'react';

const FAQ = () => {
  return(
    <div style={{background: '#eff1f2'}}>
      <div className="container">
        <div id="faq-project" className="row pt-100 pb-100">
          <div className="col-lg-3" style={{fontSize: '72px', lineHeight: '120%'}}>
            <h1>FAQ</h1>
          </div>
          <div className="col-lg-9">
            <div className="ltn__faq-inner ltn__faq-inner-2">
                <div id="accordion_2">
                {/* card */}
                <div className="card">
                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                    What Are Early-Stage Invesments?
                    </h6>
                    <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                    <div className="card-body">
                        <p>
                        Early-stage investments fund the first three stages of a company’s development- Seed, Start-up, and Early-growth.<br/><br/>

                        The funding helps an entrepreneur start a business, develop and market products, and boost manufacturing and sales.

                        </p>
                    </div>
                    </div>
                </div>
                <div className="card">
                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="false">
                    How Does It Work?
                    </h6>
                    <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                    <div className="card-body">
                      <p>
                      Open Venture secures partnerships with early-stage businesses and divides the opportunities into smaller investment opportunities called units for everyone.
                      </p><br/>
                      <p>
                      It leverages the concept of crowdfunding to lower the capital requirements for retail investors and give them access to alternative investments typically limited to hedge funds and venture capital firms.
                      </p>
                    </div>
                    </div>
                </div>
                <div className="card">
                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                      Who Can Invest?
                    </h6>
                    <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                    <div className="card-body">
                      <p>
                        Open Venture utilized blockchain to make early-stage investments available for everyone!
                      </p><br/>
                      <p>
                        Please review the list of restricted countries and requirements in the Term of Use documentation.
                      </p>
                    </div>
                    </div>
                </div>
                <div className="card">
                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                      What are the payment options?
                    </h6>
                    <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                    <div className="card-body">
                      <p>
                        Open Venture currently takes Ethereum, electronic bank transfers, and certified checks. Once the marketplace is available (Q3), you will have the flexibility to also invest with credit cards, PayPal, Google Pay, and Apple Pay.
                      </p>
                    </div>
                    </div>
                </div>
                <div className="card">
                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                      How Are OV Units Valued?
                    </h6>
                    <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                    <div className="card-body">
                      <p>
                      Open Venture’s smart contracts guarantee that each unit equals one share in the investments and secures your ownership on a blockchain ledger.
                      </p>
                      <br/>
                      <p>
                      You can review your ownership in your admin portal, and you’ll be able to transfer it to your personal Ethereum wallet. (Coming soon).
                      </p>
                    </div>
                    </div>
                </div>
                <div className="card">
                    <h6 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-6" aria-expanded="false">
                      How Do I Get Profit?
                    </h6>
                    <div id="faq-item-2-6" className="collapse" data-bs-parent="#accordion_2">
                    <div className="card-body">
                      <p>
                      Investors in Open Venture projects can profit in two different ways.
                      </p><br/>
                      <p>
                      The primary method depends on the investment type. These include but are not limited to the profit-sharing of a project sold, dividend and royalty payments, and cashout periods once businesses go public. The payouts are based on the percentage of the total project each investor’s OV Units represent.
                      </p><br/>
                      <p>
                      The second method is to sell your investment on Open Venture’s marketplace. Each time a project's valuation grows, your investment price will also rise proportionally. The marketplace is coming in late 2022.
                      </p>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ;