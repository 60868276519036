import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useContractFunction, useCall, useEthers } from '@usedapp/core';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, app, db } from '../../firebase';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useGetPrice, useTotalSupply } from '../../hooks'
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import { Form, Modal } from 'react-bootstrap';
import FormControl from 'react-bootstrap/FormControl';
import { doc, getDoc } from "firebase/firestore";
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Interface } from '@ethersproject/abi';
import { Contract } from '@ethersproject/contracts';
import { formatUnits, parseEther } from '@ethersproject/units'
import { Web3ModalButton } from '../Web3ModalButton'

import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { pdfjs } from 'react-pdf';

import './Shop.css'
import ContractData from '../../abi';


const ShopDetails = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  };
  let publicUrl = process.env.PUBLIC_URL+'/'

  const nftInterface = new Interface(ContractData['blaircreek'].abi);
  let address = ContractData['blaircreek'].address;
  let nftContract = new Contract(address, nftInterface);

  const [user, loading] = useAuthState(auth);
  const [dbUser, setDbUser] = useState("");

  useEffect(() => {
		async function setData() {
			if (user && !loading) {
				const dbuser = await getDoc(doc(db, "users",  user.uid))
				setDbUser(dbuser.data());
				console.log(dbuser.data());
			}
		}
		setData();
  }, [user, loading])

  const { account, chainId } = useEthers()
  const [mintCount, setMintCount] = useState(1);
  const [ethPriceInCad, setEthPriceInCad] = useState(0);
  const totalSupply = useTotalSupply();
  const maxTokens = ContractData['blaircreek'].maxTokens;
  const progress = ((totalSupply/maxTokens) * 100).toFixed(2);

  const [error, setError] = useState("");

  const [name, setName] = useState("");
  const [firstAgreement, setFirstAgreement] = useState(false);
  const [secondAgreement, setSecondAgreement] = useState(false);
  const [thirdAgreement, setThirdAgreement] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setError("");
  }
  const handleShow = () => setShow(true);
  //const price = ContractData['blaircreek'].price;

  const usd = useGetPrice();
  // console.log(usd);

  const decrementMint = () => {
    if (mintCount > 1) {
      setMintCount(mintCount - 1)
    }
  }

  const incrementMint = () => {
    if (mintCount < 30) {
      setMintCount(mintCount + 1)
    }
  }

  const agreementCheck = () => {
    if (mintCount > 10) {
      return !(name.length && firstAgreement && secondAgreement && thirdAgreement)
    } else {
      return !(name.length && thirdAgreement)
    }
  }

  useEffect(() => {
    if (usd.value && usd.value[0]) {
      setEthPriceInCad(formatUnits(usd.value[0], "wei"))
    }
  }, [usd])

  const { state, send } = useContractFunction(nftContract, "mint", { transactionName: "Minting" });

  // const getUpdatedPrice = async () => {
  //   console.log('here');
  //   console.log(nftContract.eth_usd_price_feed);
  //   // console.log(nftContract);
  //   let eth_usd_price_feed = await nftContract.eth_usd_price_feed();
  //   let cad_usd_price_feed = await nftContract.cad_usd_price_feed();
  //   console.log('eth, usd, cad', { eth_usd_price_feed, cad_usd_price_feed });
  // }

  // getUpdatedPrice();

  const editMintValue = (value) => {
    console.log("value", value);
    if (isNaN(value)) {
      setMintCount(0);
    } else {
      if (value > 30) {
        setMintCount(30)
      } else {
        setMintCount(value);
      }
    }
  }

  const mint = async () => {
    try {
      let tx = await send(mintCount, { value: parseEther((parseInt(1000) * mintCount / ethPriceInCad).toString()) })
      console.log(tx);
      if (!tx || state.errorMessage.includes('insufficient')) {
        setError('Insufficient funds.')
      } else {
        handleClose()
      }
    } catch (e) {
      console.log(e.message);
    }
  }

    return (
      <>
      <div id="project-section-top" className="ltn__slider-area ltn__slider-3  section-bg-1 section-bg go-top pb-50" style={{background: '#122549'}}>
        <div className="container">
          <div id="project-title" className="row pt-100">
            <div className="col-lg-6 col-xs-12 col-sm-12" style={{paddingRight: '80px'}}>
              <h1 style={{fontSize: '52px', color: '#FFF'}}>Tre’dish</h1>
            </div>
            <div className="col-lg-6 col-xs-12 col-sm-12 mt-20 mt-md-0">
              <div className="row">
                <div className="col-12 d-inline-flex">
                  <img style={{height: '40px', width: '40px', marginTop:'5px'}} src="/assets/img/location.png" />
                  <div style={{marginLeft: '10px'}}>
                    <span style={{color: '#6B717D'}}>Location</span> <br/>
                    <p style={{color: '#FFF'}}>Toronto, Ontario, Canada</p>
                  </div>
                </div>
              </div>
              <div className="row mt-20">
                <div className="col-lg-6 col-xs-12 col-sm-12">
                  <div className="row">
                    <div className="col-12 d-inline-flex">
                      <img style={{height: '40px', width: '40px', marginTop:'5px'}} src="/assets/img/available.png" />
                      <div style={{marginLeft: '10px'}}>
                        <span style={{color: '#6B717D'}}>Sold</span> <br/>
                        <p style={{color: '#FFF'}}>500/500</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-xs-12">
                  <div className="row mt-20 mt-md-0">
                    <div className="col-12 d-inline-flex">
                      <img style={{height: '40px', width: '40px', marginTop:'5px'}} src="/assets/img/nfticon.png" />
                      <div style={{marginLeft: '10px'}}>
                        <span style={{color: '#6B717D'}}>Price</span> <br/>
                        <p style={{color: '#FFF'}}>$100 CAD / Unit</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ltn__shop-details-area pb-10" style={{background: '#eff1f2'}}>
				<div className="container" style={{background: '#FFFFFF'}}>
				<div className="row">
					<div className="col-lg-12 col-md-12">
					<div className="row ltn__shop-details-inner ltn__page-details-inner mb-60">
						{/* <h1>Blair Creek Townhomes</h1>
						<label><span className="ltn__secondary-color"><i className="flaticon-pin" /></span> Kitchener/Waterloo, Ontario, Canada</label> */}
            {/* <div className="row">
              <div classNam="col-lg-12">
                <label><span>Minted: x/8490</span></label>
              </div>
            </div> */}

            {/* <>
            {typeof totalSupply === 'number'
             ? <ProgressBar animated variant="primary" now={progress} />
             : ''
            }
            </>
            <small className="mb-5" style={{float: 'right'}}>{totalSupply}/{maxTokens} ({progress}%)</small> */}
            <div id="carousel-parent" className="col-lg-6 col-xs-12 col-sm-12">
              <img id="product-carousel" src="/assets/img/tredish/1.png"/>
            </div>
            <div id="product-description" className="col-lg-6 col-xs-12 col-sm-12 mb-100" style={{paddingLeft: '30px'}}>
              <h4 className="title-2">Description</h4>
              <p>Tre’dish is a community-based SaaS platform that enables talented Home Chefs to build an audience and run a profitable business from their home kitchen by preparing and sharing authentic homemade dishes for food lovers in the area.</p>
              <p>The organization is collaborating with policymakers and local authorities to bring a safe and regulated food-sharing economy to US cities. Tre’dish is currently onboarding a new generation of empowered Home Chefs who are turning their kitchens into profitable businesses.</p>
              <p>This project will provide the Open Venture community to invest in units of Tred'ish at the seed round.</p>

              {/* <h4 className="title-2">Additional Perks</h4>
              <p>The first 100 accredited investors to mint the maximum 30 NFTs will be airdropped a Platinum OpenVenture Passport. And the first 200 non-accredited investors to mint the maximum 10 NFTS will be airdropped a Gold OpenVenture Passport. These limited OpenVenture Passports will be valued at 1 eth for Platinum and .35 eth for the Gold tier. These passports will provide the holders with exclusive access to future NFT whitelists, exclusive investment opportunities, and special access to exclusive events and conferences. </p>
              <br/> */}
            </div>
            <div id="desktop-container" style={{paddingRight: '100px', paddingLeft: '100px'}}>
            <div className="row mb-50" id="h1row">
              <h1 style={{fontSize: '58px', marginTop: '0px !important'}}>Tre'dish 2021: <br/>End of year wrap up</h1>
            </div>

            <div className="row videorow pt-30">
              <div className="embed-container">
                <iframe title="vimeo-player" src="https://player.vimeo.com/video/662701301?h=7cafc2aaef" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
              </div>
            </div>

            <div id="leadership-h1" className="row pt-50">
              <h1 style={{fontSize: '58px', marginTop: '0px !important'}}>Leadership with<br/> a track record</h1>



            {/* {user && dbUser && dbUser.kyc && account && ethPriceInCad !== 0
            ? <div className="col-lg-12 col-md-12">
                  {ethPriceInCad !== 0
                    ? <span>Mint {mintCount}/30</span>
                    : ''
                  }
                <Row>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-xs-3">
                  <InputGroup className="mb-3">
                    <Button id="plus" style={{height: '10px !important'}} onClick={incrementMint}>+</Button>
                    <input style={{marginBottom: '0px !important'}} type="number" min="1" max="30" pattern="[0-9]+" className="form-control" value={mintCount} onBlur={event => editMintValue(parseInt(event.target.value))} onChange={event => setMintCount(parseInt(event.target.value))}/>

                    <Button onClick={decrementMint}>-</Button>
                  </InputGroup>
                  </div>
                </Row>
                <Button className="mt-4" disabled={mintCount === 0} variant="primary" onClick={handleShow}>
                  Mint
                </Button>
              </div>
            :
              <>
                <Alert variant="info">{!user ? <>You must <Link to="/register">sign up</Link> before you can mint.</> : !dbUser.kyc ? <>You must complete <Link to="/kyc">KYC</Link> before minting.</> : 'You must connect your wallet to mint.'}</Alert>
                {user && dbUser && dbUser.kyc ? <Web3ModalButton /> : ''}
              </>
            } */}

            </div>

            <div id="team" className="container">
        <div className="row pt-60">
          <div className="col-lg-4">
            <div className="">
              <img src="/assets/img/tredish/Peter-Hwang.png" className="image" />
              {/* <div className="overlay">
              <p className="text">
              Charlie has 10+ years of experience in building professional services and solution engineering departments in the Fintech space.
                </p>
              </div> */}
            </div>
            <p>
              Peter Hwang
            </p>
            <span>President and CEO</span>
            <hr className="mt-20"></hr>
            <ul>
              <li>
                Nicoya Ventures <br/>
                <span>Managing Director</span>
              </li>
              <li>
                Newstrike Brands <br/>
                <span>President, Co-Founder</span>
              </li>
              <li>
                Globalfaces Direct <br/>
                <span>President, Co-Founder</span>
              </li>
              <li>
                Easylease Corp <br/>
                <span>Co-Founder</span>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <div className="peter">
              <img src="/assets/img/tredish/Jay-Wilgar.png" className="image" />
              {/* <div className="overlay">
                <p className="text">
                  Peter focuses his law practice around corporate transactions and commercial litigation, and he has helped build multiple fintech startups.
                </p>
              </div> */}
            </div>
            <p>
              Jay Wilgar
            </p>
            <span>Chief Strategy Officer</span>
            <hr className="mt-20"></hr>
            <ul>
              <li>
                Nicoya Ventures <br/>
                <span>Managing Director</span>
              </li>
              <li>
                Newstrike Brands <br/>
                <span>CEO, Co-Founder</span>
              </li>
              <li>
                Aim PowerGen Corporation <br/>
                <span>Founder</span>
              </li>
            </ul>
          </div>
          <div className="col-lg-4">
            <div className="christo">
              <img src="/assets/img/tredish/Srin-Sridharan.png" className="image" />
              {/* <div className="overlay">
                <p className="text">
                  Christo has 2 years experiece in Solidity development, 6 years in Fintech Software development, and over 10 years in total development experience.
                </p>

              </div> */}
            </div>
            <p>
              Srin Sridharan
            </p>
            <span>Chief Finance Officer</span>
            <hr className="mt-20"></hr>
            <ul>
              <li>
                Thought <br/>
                <span>Managing Partner</span>
              </li>
              <li>
                Boston Consulting Group<br/>
                <span>Management Consultant</span>
              </li>

            </ul>
          </div>
        </div>
            </div>

						</div>
					</div>

					</div>
          </div>
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Open Venture Inc.</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              {/* <Document file={'/assets/documents/blaircreek.pdf'} options={options} /> */}
              <a href="/assets/documents/blaircreek.pdf" target="_blank"><h3 style={{color: 'blue'}}>Mint Agreement (Click to View/Download)</h3></a>

              {/* <div id="legal" style={{overflow:'scroll', height:'400px'}}> */}
              {/* </div> */}
              <Form className="mt-3">
                <Form.Control onChange={(e) => { setName(e.target.value) }} type="text" value={name} placeholder="Type First and Last Name here"></Form.Control>
                <div className="mt-3">
                  {mintCount > 10
                  ? <>
                      <Form.Check checked={firstAgreement} id="agreement1" onChange={(e) => { setFirstAgreement(e.target.checked);}} aria-label="option 1" label="I verify I am an eligible investor."></Form.Check>
                      <Form.Check checked={secondAgreement} id="agreement2" onChange={(e) => { setSecondAgreement(e.target.checked);}} aria-label="option 2" label="I have read and fully understood the definition of eligible investor."/>
                    </>
                  : ''
                  }

                  <Form.Check checked={thirdAgreement} id="agreement3" onChange={(e) => { setThirdAgreement(e.target.checked);}} aria-label="option 3" label="I have read the offering memorandum prepared by Open Venture Inc."></Form.Check>
                </div>
              </Form>
              {error ?
              <Alert variant="danger">
                {error}
              </Alert>
              : ''
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Disagree
              </Button>
              <Button disabled={agreementCheck()} variant="primary" onClick={mint}>
                Agree & Mint
              </Button>
            </Modal.Footer>
          </Modal>
				</div>
      </>

    )
}

export default ShopDetails