import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, HashRouter, Route, Routes } from "react-router-dom";
import { Mainnet, Ropsten, DAppProvider } from '@usedapp/core'
import { getDefaultProvider } from 'ethers'
import { ChakraProvider } from '@chakra-ui/react'

import HomeV1 from './components/home-v1';
import AddListing from './components/add-listing'
import HomeNew from './components/home-new';
import ProductDetails from './components/product-details';

import BlogDetails from './components/blog-details';
import Contact from './components/contact';
import Cart from './components/cart';
import Checkout from './components/checkout';
import MyAccount from './components/my-account';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import KYCPage from './components/KYCPage';


class Root extends Component {
    render() {
        return(
                <Router>
	                <div>
	                <Routes>
	                    <Route path="/" element={<HomeNew/>} />

                        {/* <Route path="/about" component={About} />
                        <Route path="/service" component={Service} />
                        <Route path="/service-details" component={ ServiceDetails } />
                        <Route path="/portfolio" component={ Portfolio } />
                        <Route path="/portfolio-v2" component={ PortfolioV2 } />
                        <Route path="/portfolio-details" component={ PortfolioDetails } />
                        <Route path="/team" component={ Team } />
                        <Route path="/team-details" component={ TeamDetails } />
                        <Route path="/faq" component={ Faq } />
                        <Route path="/coming-soon" component={ ComingSoon } />
                        <Route path="/404" component={ Error } />
                        <Route path="/location" component={ Location } />
                        <Route path="/shop" component={ Shop } />
                        <Route path="/shop-grid" component={ ShopGrid } />
                        <Route path="/shop-left-sidebar" component={ ShopLeftSidebar } />
                        <Route path="/shop-right-sidebar" component={ ShopRightSidebar } /> */}

                        <Route path="/project/:name" element={ <ProductDetails/> } />
                        {/* blog */}
                        {/* <Route path="/blog-grid" component={ BlogGrid } />
                        <Route path="/blog-left-sidebar" component={ BlogLeftSidebar } />
                        <Route path="/blog-right-sidebar" component={ BlogRightSidebar } />
                        <Route path="/blog" component={ Blog } /> */}


                        {/* <Route path="/blog-details" component={ BlogDetails } /> */}
                        <Route path="/contact" element={ <Contact/> } />
                        {/* <Route path="/cart" component={ Cart } />
                        <Route path="/checkout" component={ Checkout } />
                        <Route path="/my-account" component={ MyAccount } /> */}
                        <Route path="/login" element={ <LoginPage/> } />
                        <Route path="/register" element={ <RegisterPage/> } />
                        <Route path="/kyc" element={ <KYCPage/> } />
                        {/* <Route path="/add-listing" component={ AddListing } /> */}
                        {/* <Route path="/wishlist" component={ Wishlist } />
                        <Route path="/order-tracking" component={ OrderTracking } />
                        <Route path="/history" component={ History } /> */}
	                </Routes>
	                </div>
                </Router>
        )
    }
}

export default Root;

const config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: "https://mainnet.infura.io/v3/066727117cdc48ca9645d26d0c555e1d",
  },

  multicallAddresses: 2
}

const root = ReactDOM.createRoot(document.getElementById('quarter'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <DAppProvider config={config}>
        <Root />
      </DAppProvider>
    </ChakraProvider>
  </React.StrictMode>
);