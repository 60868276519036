import React from 'react';
import { useNavigate } from 'react-router-dom';

const Clients = () => {
  const navigate = useNavigate();
  return(
    <div style={{background: '#FFF'}}>
      <div className="container" style={{paddingTop:'100px', position:'relative'}}>
        <img id="clients-icon" src="/assets/img/clients.png" style={{position: 'absolute', zIndex:'999', top:'-200px'}}/>
        <div className="row mt-130 mb-80">
          <div className="col-lg-6 mb-50" style={{fontSize: '72px', lineHeight: '120%', textAlign: 'right', paddingRight: '100px'}}>
            <h1>
              These are our clients
            </h1>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-6">
            <p className="">
              They may come from various industries, but our partners have a proven track record of building successful products and services.
            </p>
            <div className="row" style={{marginTop: '25px', height: '32px'}}>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6" style={{textAlign: 'left'}}>
                <a target="_blank" href="https://theannexhotels.com/"><img src="/assets/img/companies/annex.png" style={{height: '16px', position: 'absolute', top:'10px', left:'20px'}}/></a>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <a target="_blank" href="https://tredish.com/"><img src="/assets/img/companies/tredish.png" style={{height: '32px'}} /></a>
              </div>
            </div>
            <div className="row" style={{marginTop: '25px', marginBottom: '25px', height: '56px'}}>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <a target="_blank" href="https://www.redhillentertainment.ca/"><img src="/assets/img/companies/redhill.png" style={{height: '56px', position: 'absolute', top:'10px', left:'20px'}}/></a>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <a target="_blank" href="https://www.knowledgehook.com/"><img src="/assets/img/companies/knowledgehook.png" style={{height: '56px'}} /></a>
              </div>
            </div>
            <div>
              <button onClick={() => navigate('/register')} style={{cursor: 'pointer', background: '#227CFF', padding:'20px', color: '#FFF', marginTop:'20px', width:'100%'}}>Register</button>
              <p style={{fontSize: '14px'}}>You need to register before you can mint.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Clients;